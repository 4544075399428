import * as React from "react";
import { graphql, navigate } from "gatsby";
import clsx from 'clsx';
import Layout from "../components/layout";
import PageError from "../components/PageError";
import Icon from '../components/Icon';
import HelpScreen from "../components/HelpScreen";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { 
  Button, Paper, TextField,
  Slide, Typography 
} from '@material-ui/core';
import * as MUIIcons from '@material-ui/icons';
import MuiMarkdown from 'mui-markdown';
import { connect } from 'react-redux';
import Api from '../lib/api';
import Helper from '../lib/helper';
import UserHelper from "../lib/userHelper";
import QRCode from "react-qr-code";

import * as sessionActions from '../redux/actions/sessionActions';
import * as loggingActions from '../redux/actions/loggingActions';

import { isMobile, isBrowser } from 'react-device-detect';
import qs from 'qs';
import useCommonStyles from '../assets/style/commonStyle';

const setupBoxWidth = isMobile ? '90vw' : '40vw';
const setupBoxHeight = isMobile ? '70vh' : '60vh';

const TwoFASetupPage = (props) => {
  const {t} = useTranslation('tfasetup');
  const [busy, setBusy] = React.useState(false);
  const [showHelp, setShowHelp] = React.useState(false);
  const [autoDismissDur, setAutoDismissDur] = React.useState(0);
  const commonClasses = useCommonStyles();
  const [curStep, setCurStep] = React.useState(1);
  const [pageDir, setPageDir] = React.useState('next');

  const locale = props.ui.get('lang');
  const auth = Helper.getStateAsJSObj(props.session, 'auth', null);
  const setting = Helper.getStateAsJSObj(props.system, 'setting', null);
  const sysConfig = Helper.carefullyGetValue(setting, 'data.configs'.split('.'), []);
  const logConfig = sysConfig.find(item => item.category === 'system' && item.identifier === 'system.logging');
  const logEnabled = Helper.carefullyGetValue(logConfig, 'value.log_enable'.split('.'), false);
  const consoleLogEnabled = Helper.carefullyGetValue(logConfig, 'value.console_log_enable'.split('.'), false);
  const loginSecurityConfig = sysConfig.find(item => item.category === 'authentication' && item.identifier === 'login.security');
  let tfaEnabled = false;
  if(Helper.isNotNullAndUndefined(loginSecurityConfig?.value?.enable_tfa)) {
    tfaEnabled = loginSecurityConfig.value.enable_tfa;
  }
  const authenticators = Helper.carefullyGetValue(loginSecurityConfig, 'value.authenticator'.split('.'), []);
  const userProfile = Helper.getStateAsJSObj(props.session, 'profile.data'.split('.'), null);
  
  const frmGenerateTfaSecretRef = React.useRef(null);
  const frmEnableTfaRef = React.useRef(null);

  const [tfaValidation, setTfaValidation] = React.useState({
    password: {error: false, touched: false, message: ''},
    otp: {error: false, touched: false, message: ''}
  });
  const tfaFields = [
    {field: 'password', required: true, regExp: '', type: 'string', label: 'field.password'},
    {field: 'otp', required: true, regExp: '', type: 'string', label: 'field.otp'}
  ];
  const [pwdError, setPwdError] = React.useState('');
  const [otpError, setOtpError] = React.useState('');
  const [tfaSecret, setTfaSecret] = React.useState(null);

  // Use to register the keydown event
  React.useEffect(() => {
    const onKeyDown = (event) => {
      let key = Helper.carefullyGetValue(event, ['key'], '');
      switch (key.toLowerCase()) {
        case 'f1':
          // Show context help screen
          setShowHelp(value => !value);
          event.preventDefault();
          break;

        case 'enter': 
          if(!showHelp) {
            if(curStep === 1) {
              nextStep();
            }

            if(curStep === 2) {
              generateTfaSecret();
            }

            if(curStep === 3) {
              enableTfa();
            }

            if(curStep === 4) {
              tfaSetupDone();
            }
            
            event.preventDefault();
          }
          break;

        case 'escape': 
          if(!showHelp) {
            // Only allow to go back for the first 3 steps. If reached step 4, means tfa setupis done, so no going back.
            if(curStep <= 3) {
              prevStep();
            }
            event.preventDefault();
          }
          break;

        default:
          break;
      }
    };

    if(isBrowser) {
      document.addEventListener("keydown", onKeyDown);
    }

    return () => {
      if(isBrowser) {
        document.removeEventListener("keydown", onKeyDown);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curStep, pageDir, tfaSecret, showHelp, autoDismissDur]); // Place the curStep, pageDir, showHelp in the depenency so that when ever the these two state changed, the event listender will get the latest states.


  const autoDismiss = () => {
    if(autoDismissDur > 0) {
      if(autoDismissDur - 1 === 0) {
        // Perform dismiss logic
        tfaSetupDone();
      }

      setAutoDismissDur(autoDismissDur - 1);
    }
  };

  const nextStep = () => {
    if(curStep < 4) {
      setCurStep(curStep + 1);
      setPageDir('next');
    }
  }

  const prevStep = () => {
    if(curStep > 1) {
      setCurStep(curStep - 1);
      setPageDir('back');
    }
  }

  const slideDir = (step) => {
    let dir='';

    if(pageDir === 'next') {
      dir = curStep === step ? 'left' : 'right';
    }
    else {
      dir = curStep === step ? 'right' : 'left';
    }

    return dir;
  }

  const generateTfaSecret = () => {
    setBusy(true);
    let valid = Helper.validateFormRef(frmGenerateTfaSecretRef, tfaFields.slice(0, 1), tfaValidation, setTfaValidation, t);
    setPwdError('');
    setTfaSecret(null);
    if(valid) {
      let password = Helper.carefullyGetValue(frmGenerateTfaSecretRef, `current.elements.password.value`.split('.'), '');
      Api.tfaGenerate(password)
      .then(result => {
        setTfaSecret(result);
        nextStep();
        setBusy(false);
      })
      .catch(error => {
        setPwdError(error.message);
        setBusy(false);
        if(logEnabled) {
          error.message += ' - Api.tfaGenerate Error @ /tfasetup.js';
          props.dispatch(
            loggingActions.loggingNew(
              Helper.populateLoggingDetail(error, {
                session: props.session.toJS()
              })
            )
          );
        }
        consoleLogEnabled && console.error('Api.tfaGenerate (TFA Setup) Error: ', error);
      });
    }
    else {
      setBusy(false);
    }
  }

  const enableTfa = () => {
    setBusy(true);
    let valid = Helper.validateFormRef(frmEnableTfaRef, tfaFields.slice(1, 1), tfaValidation, setTfaValidation, t);
    setOtpError('');
    
    if(valid) {
      let otp = Helper.carefullyGetValue(frmEnableTfaRef, `current.elements.otp.value`.split('.'), '');
      Api.tfaEnable(tfaSecret.secret, otp)
      .then(_ => {
        props.dispatch(
          sessionActions.profileGet((success, result) => {
            if(!success) {
              if(logEnabled) {
                result.message += ' - sessionActions.profileGet Error @ /tfasetup.js';
                props.dispatch(
                  loggingActions.loggingNew(
                    Helper.populateLoggingDetail(result, {
                      session: props.session.toJS()
                    })
                  )
                );
              }
              consoleLogEnabled && console.error('sessionActions.profileGet (TFA Setup) Error: ', result);
            }

            nextStep();
            setTfaSecret(null);
            setBusy(false);
            setAutoDismissDur(6);
          })
        );
      })
      .catch(error => {
        setOtpError(error.message);
        setBusy(false);

        if(logEnabled) {
          error.message += ' - Api.tfaEnable Error @ /tfasetup.js';
          props.dispatch(
            loggingActions.loggingNew(
              Helper.populateLoggingDetail(error, {
                session: props.session.toJS()
              })
            )
          );
        }
        consoleLogEnabled && console.error('Api.tfaEnable (TFA Setup) Error: ', error);
      });
    }
    else {
      setBusy(false);
    }
  }

  const tfaSetupDone = () => {
    let queryParam = null;
    if(Helper.stringHasValue(props.location?.search))
      queryParam = qs.parse(props.location.search.substr(1));

    let redirectURL = Helper.carefullyGetValue(queryParam, ['from'], '');
    // If no from url pass via the query string, then get the user home url via role
    if(!Helper.stringHasValue(redirectURL)) {
      redirectURL = UserHelper.getHomeRouteByRole(setting, auth?.data?.role, '/');
    }
    
    navigate(redirectURL);
  }

  return (
    <Layout showMenu={false} busy={busy} allowRedirect={false} interval={autoDismiss}>
      {
        !tfaEnabled &&
        <div className={commonClasses.flexMiddleCenter}>
          <PageError 
            title={t('error.tfaNotEnabled.title')} 
            message={t('error.tfaNotEnabled.message')} />
        </div>
      }
      {
        tfaEnabled &&
        <div className={commonClasses.flexMiddleCenter}>
          <Slide direction={slideDir(1)} in={curStep === 1} mountOnEnter unmountOnExit>
            <Paper variant={'elevation'} elevation={4} 
              className={clsx(commonClasses.contentBox)}
              style={{
                width: setupBoxWidth,
                minHeight: setupBoxHeight
              }}
            >
              <div className={commonClasses.flexCenter}
                style={{
                  flexDirection: 'column'
                }}
              >
                <Typography variant='h5' gutterBottom>
                  {t('prompt.tfasetup.step1')}
                </Typography>
                <div className={commonClasses.extraLargeIcon}>
                  <MUIIcons.VerifiedUser />
                </div>
              </div>
              <div className={commonClasses.flexFullColumn} >
                <Typography variant='body1' style={{marginBottom: 20}}>
                  {t('prompt.tfasetup.step1.desc')}
                </Typography>
                {
                  authenticators.map(authApp => (
                    <div key={`authenticator_${authApp.brand}`} style={{marginBottom: 20}}>
                      <Typography variant='h6'
                        style={{
                          marginBottom: 20,
                          textAlign: 'center'
                        }}
                      >
                        {authApp.name.find(name => name.lang === locale)?.text}
                      </Typography>
                      <div 
                        className={commonClasses.flexFullRow}
                        style={{
                          justifyContent: 'space-around',
                          flexWrap: 'wrap',
                          marginBottom: 20,
                          rowGap: 30
                        }}
                      >
                        {
                          authApp.platform?.map(os => (
                            <div key={`authenticator_${authApp.brand}_${os.type}`} className={clsx(commonClasses.flexColumn, commonClasses.flexCenter)}
                              style={{

                              }}
                            >
                              <div style={{marginBottom: 5}}>
                                <Icon source={{name: os.icon}} fontSize='large' />
                              </div>
                              <a href={os.download_url} target='_blank' rel="noreferrer">
                                <QRCode value={os.download_url} size={150} />
                              </a> 
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  ))
                }
                
              </div>
              <div className={commonClasses.flexRow}
                style={{
                  justifyContent: 'flex-end'
                }}
              >
                <Button variant='contained' color='primary' onClick={() => nextStep()}>
                  {t('button.next')}
                </Button>
              </div>
              
            </Paper>
          </Slide>
          <Slide direction={slideDir(2)} in={curStep === 2} mountOnEnter unmountOnExit>
            <Paper variant={'elevation'} elevation={4} 
              className={clsx(commonClasses.contentBox)}
              style={{
                width: setupBoxWidth,
                minHeight: setupBoxHeight
              }}
            >
              <div className={commonClasses.flexCenter}
                style={{
                  flexDirection: 'column'
                }}
              >
                <span style={{fontSize: '2em', marginBottom: 10}}>
                  {t('prompt.tfasetup.step2')}
                </span>
                <div className={commonClasses.extraLargeIcon}>
                  <MUIIcons.VerifiedUser />
                </div>
                
              </div>
              <form id='frmGenerateTfaSecret' ref={frmGenerateTfaSecretRef} className={commonClasses.flexFullColumn}
                  onSubmit={(event) => {
                    generateTfaSecret();
                    event.preventDefault();
                  }}
                >
                <div className={commonClasses.flexFullColumn} >
                  <Typography variant='body1' style={{marginBottom: 20, textAlign: 'center'}}>
                    {t('prompt.tfasetup.step2.desc')}
                  </Typography>
                  <input id="username" 
                    autoComplete='username'
                    value={userProfile?.email} 
                    disabled={true}
                    hidden={true}
                  />
                  <TextField id="password" label={t('field.password')} 
                    style={{marginBottom: 10}}
                    variant="outlined" 
                    autoComplete='password'
                    type={'password'}
                    error={tfaValidation.password.error}
                    helperText={tfaValidation.password.message}
                    onBlur={(event) => Helper.validateFormField('password', event.target.value, tfaFields, tfaValidation, setTfaValidation, t)}
                    autoFocus={isBrowser && curStep === 2} // Focus only if in browser and current step
                  />
                  
                  {
                    Helper.stringHasValue(pwdError) &&
                    <Typography variant='subtitle1' color="error">
                      {pwdError}
                    </Typography>
                  }
                </div>
                <div className={commonClasses.flexRow}
                  style={{
                    justifyContent: 'space-between'
                  }}
                >
                  <Button variant='contained' onClick={() => prevStep()}>
                    {t('button.back')}
                  </Button>
                  <Button variant='contained' color='primary' type='submit'>
                    {t('button.next')}
                  </Button>
                </div>
              </form>
            </Paper>
          </Slide>
          <Slide direction={slideDir(3)} in={curStep === 3} mountOnEnter unmountOnExit>
            <Paper variant={'elevation'} elevation={4} 
              className={clsx(commonClasses.contentBox)}
              style={{
                width: setupBoxWidth,
                minHeight: setupBoxHeight
              }}
            >
              <div className={commonClasses.flexCenter}
                style={{
                  flexDirection: 'column'
                }}
              >
                <span style={{fontSize: '2em', marginBottom: 10}}>
                  {t('prompt.tfasetup.step3')}
                </span>
                <div className={commonClasses.extraLargeIcon}>
                  <MUIIcons.VerifiedUser />
                </div>
                
              </div>
              <form id='frmEnableTfa' ref={frmEnableTfaRef} className={commonClasses.flexFullColumn}
                  onSubmit={(event) => {
                    enableTfa();
                    event.preventDefault();
                  }}
                >
                <div className={commonClasses.flexFullColumn} >
                  <Typography variant='body1' style={{marginBottom: 20, textAlign: 'center'}}>
                    {t('prompt.tfasetup.step3.desc')}
                  </Typography>
                  {
                    Helper.isNotNullAndUndefined(tfaSecret) &&
                    <div className={clsx(commonClasses.flexColumn, commonClasses.flexCenter)} style={{padding: 10}}>
                      <Typography variant='body1' style={{marginBottom: 10, textAlign: 'center'}}>
                        {tfaSecret.secret}
                      </Typography>
                      <QRCode value={tfaSecret.otpauth_url} size={200} />
                    </div>
                  }
                  <TextField id="otp" label={t('field.otp')} 
                    style={{marginBottom: 10}}
                    variant="outlined" 
                    error={tfaValidation.otp.error}
                    type='number'
                    helperText={tfaValidation.otp.message}
                    onBlur={(event) => Helper.validateFormField('otp', event.target.value, tfaFields, tfaValidation, setTfaValidation, t)}
                    autoFocus={isBrowser && curStep === 3} // Focus only if in browser and current step
                  />
                  {
                    Helper.stringHasValue(otpError) &&
                    <Typography variant='subtitle1' color="error">
                      {otpError}
                    </Typography>
                  }
                </div>
                <div className={commonClasses.flexRow}
                  style={{
                    justifyContent: 'space-between'
                  }}
                >
                  <Button variant='contained' onClick={() => prevStep()}>
                    {t('button.back')}
                  </Button>
                  <Button variant='contained' color='primary' type='submit'>
                    {t('button.next')}
                  </Button>
                </div>
              </form>
            </Paper>
          </Slide>
          <Slide direction={slideDir(4)} in={curStep === 4} mountOnEnter unmountOnExit>
            <Paper variant={'elevation'} elevation={4} 
              className={clsx(commonClasses.contentBox)}
              style={{
                width: setupBoxWidth,
                minHeight: setupBoxHeight
              }}
            >
              <div className={commonClasses.flexCenter}
                style={{
                  flexDirection: 'column'
                }}
              >
                <span style={{fontSize: '2em', marginBottom: 10}}>
                  {t('prompt.tfasetup.complete')}
                </span>
                <div className={commonClasses.extraLargeIcon}>
                  <MUIIcons.VerifiedUser />
                </div>
                
              </div>
              <div className={commonClasses.flexFullColumn}>
                <Typography variant='body1' style={{marginBottom: 20}}>
                  {t('prompt.tfasetup.complete.desc')}
                </Typography>
              </div>
              <div className={commonClasses.flexRow}
                style={{
                  justifyContent: 'center'
                }}
              >
                <Button variant='contained' color='primary' onClick={() => tfaSetupDone()}>
                  {`${t('button.done')} (${autoDismissDur})`}
                </Button>
              </div>
            </Paper>
          </Slide>
        </div>
      }
      {/* Help Screen */}
      <HelpScreen
        open={showHelp}
        onClose={_ => setShowHelp(false)}
        title={t('dialog.help.title')}
      >
        <Typography variant='body1' style={{marginBottom: 20}}>
          {t('dialog.help.desc')}
        </Typography>
        <Typography variant='body1' style={{marginBottom: 20}}>
          {t('dialog.help.functions')}
        </Typography>
        <MuiMarkdown>
          {t('dialog.help.keys')}
        </MuiMarkdown>
      </HelpScreen>
    </Layout>
  );
}

export default connect((state) => {
  return {
    session: state.session,
    system: state.system,
    ui: state.ui
  };
})(TwoFASetupPage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
